/**
 * setup client and mount app, any external imports should be code split via dynamic imports
 * this ensures that we optmize setup code for main entry into different chunks that can be
 * long term chached
 */
export async function client(): Promise<void> {
  // setup config so that from this point onwards we can safely use
  // the runtime config
  await import('@attest/config').then(config => config.initialise())

  // Setup feature switch client before progressing so from this point on
  // we can stably use feature switches.
  await import('@attest/feature-switch').then(fs => fs.initialiseFeatureSwitchClient())

  // Start loading all the packages we will need to set up the client
  const a3 = import('@attest/a3')
  const attestUser = import('@attest/user')
  const routerSetup = import('./setup/router')
  const trackingSetup = import('./setup/tracking')
  const progressBarSetup = import('./setup/progress-bar')

  // BUG in vite where the import().then syntax doesn't correctly code split
  const { router, app } = await (await import('@attest/_/setup/app')).setupApp()

  if ('Cypress' in globalThis) {
    ;(globalThis as any).__INTERNALS__ = {
      router,
    }
  }

  await a3.then(a3 => a3.useSetupA3AxiosAuthenticator())

  // setup initial authentication of user
  const { profilePromise } = await a3.then(async a3 => {
    const a3Store = a3.useA3Store()
    const { useUserStore } = await attestUser
    const userStore = useUserStore()

    if (!a3Store.isAuthenticated) {
      await a3Store.authenticate()
      return {
        profilePromise: a3Store.isAuthenticated
          ? userStore.getUserAndAdditionalInfo()
          : Promise.resolve(undefined),
      }
    } else {
      return { profilePromise: userStore.getUserAndAdditionalInfo() }
    }
  })

  router.beforeResolve(async () => {
    try {
      await profilePromise
      return true
    } catch {
      return true
    }
  })

  // setup router hooks
  await routerSetup.then(async r => r.useSetupRouterHooks(router))

  // setup essential application pre-resiqites
  await Promise.all([
    trackingSetup.then(tracking => tracking.setupTracking(router)),
    progressBarSetup.then(progressBar => progressBar.useSetupProgressBar(router)),
  ])

  // initialise router and mount app after initial resolving
  app.use(router)
  await router.isReady()
  app.mount('#app')

  await removeLoadingPlaceholder()
}

async function removeLoadingPlaceholder(): Promise<void> {
  const loading = document.getElementById('loading')
  if (!loading) return
  loading.dataset.loaded = 'true'
  loading.addEventListener('transitionend', () => {
    loading?.remove()
    document.getElementById('loading-style')?.remove()
  })
}
